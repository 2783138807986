import React from "react";

import styles from "./AsideText.module.scss";

const AsideText = (props) => {
	const {
		className,
		text,
		withArrow,
	} = props;

	return <aside className={`${styles.aside} ${withArrow ? "_arrow" : ""} ${className}`}>
		<span className={styles.asideText}>
			{text}
		</span>
	</aside>
};

export default AsideText;