import React, {Component} from "react";
import "components/common/liquid";
import { isRetinaLikeDisplay } from 'utils/detect';

import introImage from "img/intro.jpg";
import introImage2x from 'img/intro@2x.png';

import AsideText from "components/AsideText/AsideText";

import styles from "./Intro.module.scss";

class Intro extends Component {

	componentDidMount() {
		const actualIntroImage = isRetinaLikeDisplay() ? introImage2x : introImage;
		new window.CanvasSlideshow({
			sprites: [actualIntroImage],
			displacementImage: 'clouds.jpg',
			autoPlay: true,
			autoPlaySpeed: [0.3, 0.3],
			displaceScale: [800, 500],
			displaceAutoFit: true,
			displacementCenter: true,
			interactive: true,
			interactionEvent: 'hover', // 'click', 'hover', 'both'
			appendTo: `.${styles.image}`,
			fullScreen: true,
			stageWidth: 1319,
			stageHeight: 949,
		});
	}

	render() {
		return <div className={styles.container}>
			<h1 className={styles.title}>
				Digital design and development agency that solves all your problems from the bottom to the top
			</h1>
			<div className={styles.content}>
				<AsideText className={styles.aside} text="scroll down" withArrow={true}/>
				<div className={styles.image}>
					<p className={styles.imageP}>
						Websites Apps<br/>UX UI Branding
					</p>
				</div>
			</div>
		</div>
	}
}

export default Intro;
