import React from "react";

import styles from "./Ticker.module.scss";

const Ticker = () => (
	<div className={styles.container}>
		<div className={styles.ducks}></div>
		<div className={styles.text}>
			design    marketing   digital   branding   kittens   design   programming   design    marketing   digital   branding   kittens   design   programming    design    marketing   digital   branding   kittens   design   programming   design    marketing   digital   branding   kittens   design   programming   design    marketing   digital   branding   kittens   design   programming    design    marketing   digital   branding   kittens   design   programming
		</div>
	</div>
);

export default Ticker;