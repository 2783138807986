import React from "react";
import {withRouter, Link} from "react-router-dom";
import {TweenLite} from "gsap";
import scrollTo from "gsap/ScrollToPlugin";

import {ReactComponent as Logo} from "img/logo.svg";
import duck from "img/duck-yellow.png";

import styles from "./Header.module.scss";

const plugins = [scrollTo];

const Header = () => (
	<header className={styles.container}>
		<Link to="/" className={styles.logo}>
			foam
		</Link>
		<button className={styles.inTouch} onClick={() => TweenLite.to(window, 1, {scrollTo: '#contact'})}>
			<Logo/>
			<img src={duck} alt="duck" className={styles.duck}/>
		</button>
	</header>
);

export default withRouter(Header);