import React from "react";
import { Switch, Route } from "react-router-dom";

import Mainpage from "components/Mainpage/Mainpage";

function Routing() {
  return (
      <Switch>
          <Route exact path="/" component={Mainpage}/>
      </Switch>
  );
}

export default Routing;
