import React from "react";

import AsideText from "components/AsideText/AsideText";
import Ticker from "components/Footer/Ticker/Ticker";

import styles from "./Footer.module.scss";

const Footer = () => (
	<div className={styles.container}>
		<AsideText className={styles.aside} text="office at penn station"/>
		<div className={styles.row}>
			<h3 className={styles.title}>
				Visit us
			</h3>
			<p className={styles.p}>
				315 W 36th, #6050, New York, NY 10018
			</p>
		</div>
		<div className={styles.row}>
			<h3 className={styles.title}>
				Or email us
			</h3>
			<a href="mailto:hey@foam.nyc" className={styles.p}>
				hey@foam.nyc
			</a>
		</div>
		<Ticker className={styles.ticker}/>
	</div>
);

export default Footer;