import React, {Component} from "react";
import Slider from "react-slick";

import projectImage1 from "img/projects/project-1.png";
import projectImage2 from "img/projects/project-2.png";
import projectImage3 from "img/projects/project-3.png";
import projectImage4 from "img/projects/project-4.png";
import projectImage5 from "img/projects/project-5.png";
import projectImage6 from "img/projects/project-6.png";

import styles from "./Projects.module.scss";

class Projects extends Component {
	componentDidMount() {
		this.checkVisibility();
		window.addEventListener("scroll", this.checkVisibility);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.checkVisibility);
	}

	checkVisibility = () => {
		if(!this.list || !this.slider) {
			return;
		}

		const windowHeight = window.innerHeight;
		const listHeight = this.list.offsetHeight;
		const listTop = this.list.getBoundingClientRect().top;

		if(listTop < windowHeight && ((listTop + listHeight) > 0)) {
			if(!this.autoplay) {
				this.autoplay = true;
				this.slider.slickPlay();
			}
		} else {
			if(this.autoplay) {
				this.autoplay = false;
				this.slider.slickPause();
			}
		}
	}

	sliderSettings = {
		slidesToShow: 2,
		infinite: true,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		speed: 750,
		afterChange: () => this.afterChange(),
		responsive: [
			{
				breakpoint: 682,
				settings: {
					slidesToShow: 1
				},
			},
		],
	};

	afterChange() {
		if (this.slider) {
			this.slider.innerSlider.clickable = true;
		}
	}

	images = [
		{
			link: "https://landing.adobe.com/en/na/dynamic-media/ctir-2755/dynamic-assets.html",
			image: projectImage1,
		},
		{link: "https://www.xaxis.com/", image: projectImage2,},
		{link: "http://www.nerdskincare.com/", image: projectImage3,},
		{link: "https://sparkswell.com/", image: projectImage4,},
		{link: "https://www.traxnyc.com/", image: projectImage5,},
		{link: "https://hiyoume.io/", image: projectImage6,},
	];

	render() {
		return (
			<div className={styles.container}>
				<h2 className={`title-2 ${styles.title}`}>
					Check out<br/>
					our projects
				</h2>
				<div className={styles.list} ref={el => this.list = el}>
					<Slider {...this.sliderSettings} ref={el => this.slider = el}>
						{this.images.map((i, k) => (
							<a
								target="_blank"
								href={i.link}
								className={styles.link}
								key={k}
							>
								<img src={i.image} alt=""/>
							</a>
						))}
					</Slider>
				</div>
			</div>
		)
	}
}

export default Projects;