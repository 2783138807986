import React from "react";

import AsideText from "components/AsideText/AsideText";

import styles from "./Clients.module.scss";

const Clients = () => (
	<div className={styles.container}>
		<h2 className={`title-2 ${styles.title}`}>
			Our clients
		</h2>
		<p className={styles.p}>
			<span>Adobe</span> <span>Google</span> <span>Xaxis</span> <span>Uber</span> <span>Sony</span> <span>ADP</span> <span>BASIC</span> <span>Droga5</span> <span>Datto</span> <span>Spinrilla</span> <span>Extensis</span> <span>Looking&nbsp;Glass</span> <span>Nerd&nbsp;Skincare</span> <span>Viventium</span>
		</p>
		<AsideText className={styles.aside} text="working hard"/>
	</div>
);

export default Clients;