import React, {Component} from "react";
import "components/common/liquid";
import { isRetinaLikeDisplay } from 'utils/detect';

import image from "img/contact.jpg";
import image2x from 'img/contact@2x.png';

import styles from "./Contact.module.scss";

class Contact extends Component {
	componentDidMount() {
		const actualContactImage = isRetinaLikeDisplay() ? image2x : image;
		new window.CanvasSlideshow({
			sprites: [actualContactImage],
			displacementImage: 'clouds.jpg',
			autoPlay: true,
			autoPlaySpeed: [0.3, 0.3],
			displaceScale: [800, 500],
			displaceAutoFit: true,
			displacementCenter: true,
			interactive: true,
			interactionEvent: 'hover', // 'click', 'hover', 'both'
			appendTo: `.${styles.container}`,
			fullScreen: true,
			stageWidth: 1480,
			stageHeight: 703,
		});
	}

	render() {
		return <div className={styles.container} id="contact" onClick={() => this.link.click()}>
			<a ref={el => this.link = el} href="tel:+1-800-674-7976" className={styles.title}>
				Contact us<br/>
				(800) 674-7976
			</a>
		</div>
	}
}

export default Contact;
