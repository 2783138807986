import React from "react";

import Intro from "components/Intro/Intro";
import Projects from "components/Projects/Projects";
import Clients from "components/Clients/Clients";
import Contact from "components/Contact/Contact";
import Footer from "components/Footer/Footer";

import styles from "./Mainpage.module.scss";

const Mainpage = () => (
	<div className={styles.container}>
		<Intro/>
		<Projects/>
		<Clients/>
		<Contact/>
		<Footer/>
	</div>
);

export default Mainpage;